<template>
  <section id="scheduler" :class="{ 'hidden-input': !visible }">
    <h2 class="title" v-if="showTitle">
      {{
        lang.audiences.add.wizard.audienceSend.scheduledSend.title[
          languageSelected
        ]
      }}
    </h2>

    <div id="datetime-wrapper">
      <label for="datetime">
        {{
          lang.audiences.add.wizard.audienceSend.scheduledSend.datetime[
            languageSelected
          ]
        }}
      </label>

      <datetime
        v-model="date"
        type="datetime"
        id="datetime"
        input-class="vs-inputx vs-input--input normal"
        class="theme-orange"
        :phrases="{
          ok: 'Ok',
          cancel: lang.audiences.add.wizard.cancel[languageSelected]
        }"
        :min-datetime="minDatetime"
        :auto="true"
        ref="datetime"
      ></datetime>
      <!-- :value-zone="valueZone" -->
    </div>

    <TimeZoneSelector
      v-show="showTimezone"
      v-model="timeZone"
      :showLabel="true"
      class="mt-4"
    />

    <div class="mt-10" v-if="showActions">
      <vs-button
        class="float-right"
        icon-pack="feather"
        icon="icon-send"
        color="success"
        :disabled="!date"
        @click="scheduledSend"
      >
        {{ lang.audiences.add.wizard.audienceSend.send[languageSelected] }}
      </vs-button>

      <vs-button
        type="flat"
        class="float-right"
        icon-pack="feather"
        icon="icon-x"
        color="danger"
        @click="cancel"
      >
        {{ lang.audiences.add.wizard.cancel[languageSelected] }}
      </vs-button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { Datetime } from 'vue-datetime'

import Helper from '@/store/Helper'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 'Scheduler',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showTimezone: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: null,
      timeZone: Helper.getBrowserTimeZone()
    }
  },
  watch: {
    date() {
      if (this.date) {
        this.$emit('onSelect', this.getSchedule())
      }
    }
  },
  components: {
    datetime: Datetime,
    TimeZoneSelector: () => import('@/components/TimeZoneSelector.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    // valueZone() {
    //   return Intl.DateTimeFormat().resolvedOptions().timeZone
    // },
    minDatetime() {
      // within 10 minutos
      let date = new Date()
      let date2 = new Date(date)
      date2.setMinutes(date.getMinutes() + 10)
      return date2.toISOString()
    }
  },
  methods: {
    openPopup() {
      document.getElementsByClassName('vdatetime-input')[0].click()
    },
    getSchedule() {
      return {
        // date: this.showTimezone
        //   ? this.date.slice(0, 23).concat('Z')
        //   : this.date,
        date: this.date,
        timezone: this.timeZone.text
      }
    },
    scheduledSend() {
      this.$emit('onSend', this.getSchedule())
    },
    cancel() {
      this.$emit('onCancel')
    }
  }
}
</script>

<style lang="scss">
.theme-orange .vdatetime-popup__header,
.theme-orange .vdatetime-calendar__month__day--selected > span > span,
.theme-orange .vdatetime-calendar__month__day--selected:hover > span > span {
  background: rgba(var(--vs-primary), 1);
}

.theme-orange .vdatetime-year-picker__item--selected,
.theme-orange .vdatetime-time-picker__item--selected,
.theme-orange .vdatetime-popup__actions__button {
  color: rgba(var(--vs-primary), 1);
}

.hidden-input {
  #datetime-wrapper {
    max-height: 0;
    label,
    input {
      visibility: hidden;
    }
  }
}
</style>
