var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ 'hidden-input': !_vm.visible },attrs:{"id":"scheduler"}},[(_vm.showTitle)?_c('h2',{staticClass:"title"},[_vm._v("\n    "+_vm._s(_vm.lang.audiences.add.wizard.audienceSend.scheduledSend.title[
        _vm.languageSelected
      ])+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"datetime-wrapper"}},[_c('label',{attrs:{"for":"datetime"}},[_vm._v("\n      "+_vm._s(_vm.lang.audiences.add.wizard.audienceSend.scheduledSend.datetime[
          _vm.languageSelected
        ])+"\n    ")]),_vm._v(" "),_c('datetime',{ref:"datetime",staticClass:"theme-orange",attrs:{"type":"datetime","id":"datetime","input-class":"vs-inputx vs-input--input normal","phrases":{
        ok: 'Ok',
        cancel: _vm.lang.audiences.add.wizard.cancel[_vm.languageSelected]
      },"min-datetime":_vm.minDatetime,"auto":true},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_vm._v(" "),_c('TimeZoneSelector',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTimezone),expression:"showTimezone"}],staticClass:"mt-4",attrs:{"showLabel":true},model:{value:(_vm.timeZone),callback:function ($$v) {_vm.timeZone=$$v},expression:"timeZone"}}),_vm._v(" "),(_vm.showActions)?_c('div',{staticClass:"mt-10"},[_c('vs-button',{staticClass:"float-right",attrs:{"icon-pack":"feather","icon":"icon-send","color":"success","disabled":!_vm.date},on:{"click":_vm.scheduledSend}},[_vm._v("\n      "+_vm._s(_vm.lang.audiences.add.wizard.audienceSend.send[_vm.languageSelected])+"\n    ")]),_vm._v(" "),_c('vs-button',{staticClass:"float-right",attrs:{"type":"flat","icon-pack":"feather","icon":"icon-x","color":"danger"},on:{"click":_vm.cancel}},[_vm._v("\n      "+_vm._s(_vm.lang.audiences.add.wizard.cancel[_vm.languageSelected])+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }